import { getFeatureFlagValue } from './helpers';
import { ICommonFeatures } from './interface';

export const commonFeatures: ICommonFeatures = {
    isCA9685_localAuthLoginScreen: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9685_LOCAL_AUTH_LOGIN_SCREEN,
    ),
    isCA11388On_TermsOfServiceLoginScreen: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11388_TERMS_OF_SERVICE_LOGIN_SCREEN,
    ),
    isCA10347_useAppLoaderHookOn: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10347_ADD_USE_APP_LOADER_HOOK,
    ),
    enableAuth0Option: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_ENABLE_AUTH0_OPTION,
    ),
    newLoginMessage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_NEW_LOGIN_MESSAGE,
    ),
    isCA12555On_ScaleTicketCommodityDropdownBugFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12555_SCALE_TICKET_COMMODITY_DROPDOWN_BUG_FIX,
    ),
};
