import { currentDate_deprecated } from '../../../../helpers';
import { LbsTonsToggleSwitchConstants } from './ManageCommoditiesConstants';
import {
    CommodityType,
    CommodityUnit,
    ContractStatus,
    IManageCommoditiesInitialState,
    OpenScaleTicketContractStatus,
} from '../interfaces/ManageCommoditiesInterface';

export const MANAGE_COMMODITIES_INITIAL_STATE: IManageCommoditiesInitialState =
    {
        adjustmentsLoading: true,
        commoditiesLoading: true,
        contractScaleTicketsLoading: true,
        contractsLoading: true,
        openScaleTicketLoading: true,
        adjustmentsCanCallApi: true,
        commoditiesCanCallApi: true,
        commoditiesUnit: CommodityUnit.Lbs,
        commoditiesFilter: {
            limit: 20,
            offset: 0,
            commodityStatus: 'Status eq Active',
        },
        adjustmentsFilter: {
            limit: 20,
            offset: 0,
            commodityStatus: 'Status eq Active',
        },
        contractsFilter: {
            limit: 20,
            offset: 0,
            status: ContractStatus.Open,
        },
        contractScaleTicketFilter: {
            limit: 20,
            offset: 0,
        },
        openScaleTicketFilter: {
            limit: 20,
            offset: 0,
            contractStatus: OpenScaleTicketContractStatus.All,
        },
        openScaleTicketsSelectAll: false,

        commoditiesList: [],
        commodityId: 0,
        commodityAction: '',
        fetchedCommodityDetails: {
            name: '',
            dryMatterPercentage: '',
            priceEffectiveDate: currentDate_deprecated('YYYY-MM-DD'),
            price: '',
            unit: CommodityUnit.Lbs,
            status: 1,
            version: 0,
            type: '',
            tankId: null,
        },
        commodityDetails: {
            name: '',
            dryMatterPercentage: '',
            priceEffectiveDate: currentDate_deprecated('YYYY-MM-DD'),
            price: '',
            unit: CommodityUnit.Lbs,
            status: 1,
            version: 0,
            type: CommodityType.Dry,
            tankId: null,
        },
        commodityDetailsError: {
            name: false,
            dryMatterPercentage: false,
            priceEffectiveDate: false,
            price: false,
            tankId: false,
        },
        commodityAdjustmentHistoryList: [],
        adjustmentHistoryList: [],
        adjustmentHistoryListUnit: LbsTonsToggleSwitchConstants.lbs,
        adjustmentCommoditiesDetails: [],
        adjustmentCommoditiesDetailsError: {
            0: { dateError: false },
        },
        fetchedAdjustmentCommoditiesDetails: [],
        earliestGeneratedInvoiceCutoffDate: '',
        contracts: [],
        selectedContract: { id: '', name: '' },
        selectedContractError: false,
        contractScaleTickets: [],
        openScaleTickets: [],
        contractHasScaleTickets: true,
        tanks: [],
        submitPending: false,
    };
