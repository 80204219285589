import { ChangeEvent } from 'react';
import { IGenericFilter } from './CommonInterface';
import {
    IFMFeedlot,
    IFMLot,
    IOwner,
    IPenRationHistory,
} from './FeedlotInterface';
import { ILotSummary } from './LotSummaryInterface';
import { IRation, IRationIngredient } from './RationInterface';
import { date, dateForThirdPartyComponents } from '../../helpers';
import { IProcessingWorkset } from './HealthSection/LotProcessingWorksheet';

export interface IInvoice {
    invoiceId?: number;
    feedlotId: number;
    feedlot?: IFMFeedlot;
    billingStartDate: dateForThirdPartyComponents | string;
    billingEndDate: dateForThirdPartyComponents | string;
    lotId: number;
    lot?: IFMLot;
    lotSummary?: ILotSummary;
    expectedDateRanges?: IExpectedDateRange;
    isPreview: boolean;
    state: string;
    suggestedName?: string;
    totalCharges?: number;
    lifetimeCharges?: number;
}

export interface IProcessingWorksetGroup {
    lotId: number;
    worksheets: IProcessingWorkset[];
}

export enum BillingTab {
    GenerateBillingInvoices = 'generateBillingInvoices',
    GenerateFinalBilling = 'generateFinalBilling',
    PastBillingInvoices = 'pastBillingInvoices',
    AssignFeedCharges = 'assignFeedCharges',
    MiscCharges = 'miscCharges',
}

export interface IDailyCharge {
    chargeDate: string;
    hdCount: number;
    feedCharge: number;
    yardageCharge: number;
    medCharge: number;
    processingCharge: number;
    brandsCharge: number;
    cviCharge: number;
    miscCharge: number;
    miscCredit: number;
    totalCharge: number;
    cumulativeCharge: number;
}

export interface IDailyChargeComponent {
    date: string | dateForThirdPartyComponents;
    chargeType: string;
    chargeInCents: number;
    headCount: number;
}

export interface IFeedCharge {
    rationId: number;
    rationName: string;
    rationFeedAmount: number;
    rationChargeTotal: number;
    costPerTonWithMarkup: number;
}

export interface IBillingLot extends IFMLot {
    daysSinceShipped?: number;
    monthsSinceShipped?: number;
    lotSummary?: ILotSummary;
    expectedDateRange?: IExpectedDateRange;
}
export interface IBillingCharge {
    baseChargeId: number;
    chargeInCents: number;
    date: dateForThirdPartyComponents | string;
    lotId: number;
    chargeType: string;
    headCount: number;
    penRationHistory?: IPenRationHistory;
    deliveredAmount?: number;
    rationVersion?: IRationVersion;
    rationVersionId?: number;
}
export interface IRationVersion {
    rationVersionId: number;
    rationId: number;
    ration: IRation;
    categoryId: number;
    category: IRationType;
    version: number;
    effectiveDate: string;
    effectiveTime: string;
    markUp: number;
    loadSize: number;
    customPrice: number;
    corporatePrice: number;
    rationIngredients: IRationIngredient[];
}

export interface IRationType {
    rationTypeId: number;
    category: string;
}
export interface IPDFResponseInvoiceCharges {
    feedCharges: IFeedCharge[];
    //NOTE This was a typo 'proccessingCharges' I made in the BE(is needed in FE so mapping is correct), and will need to be update across ENV's at same time.
    proccessingCharges: number;
    medicalCharges: number;
    yardageCharges: number;
    cviBrandsCharges: number;
    miscCharges: IPDFResponseMiscCharge;
    miscCredits: IPDFResponseMiscCharge;
}

export interface IInvoiceCharge {
    feedCharges: IFeedCharge[];
    processingCharges: number;
    medicalCharges: number;
    yardageCharges: number;
    cviBrandsCharges: number;
    miscCharges: IInvoiceMiscCharge;
    miscCredits: IInvoiceMiscCharge;
}

export interface IInvoiceOwner extends IOwner {
    address1: string;
    postalCode: string;
}

export interface IPDFResponseMiscCharge {
    miscTotal: number;
    miscFeed: number;
    miscBedding: number;
    //NOTE This was a typo 'miscProccessing' I made in the BE(is needed in FE so mapping is correct), and will need to be update across ENV's at same time.
    miscProccessing: number;
    miscMedicine: number;
    miscYardage: number;
    miscBrandsCVI: number;
    miscOther: number;
}

export interface IInvoiceMiscCharge {
    miscTotal: number;
    subcharges: {
        miscFeed: number;
        miscBedding: number;
        miscProcessing: number;
        miscMedicine: number;
        miscYardage: number;
        miscBrandsCVI: number;
        miscOther: number;
    };
}

export interface IExpectedDateRange {
    startDate: string | dateForThirdPartyComponents;
    endDate: string | dateForThirdPartyComponents;
}

export interface IInvoicePDFRequestModel {
    feedLot: IFMFeedlot;
    dateIn: date | string;
    lot: IFMLot;
    owner: IInvoiceOwner;
    currentHeadCount: {
        purchased: number;
        sold: number;
        dead: number;
        periodDead?: number;
        realizers: number;
        remaining: number;
    };
    dateRange: IExpectedDateRange;
    yardageCharge: number;
    statementDate: date | string;
    charges: IPDFResponseInvoiceCharges;
    periodToDate: IToDateSummary;
    lifetimeToDate: IToDateSummary;
    isPreview: boolean;
}

export interface IBillingShipRecord {
    shipCattleId: number;
    dateReceived: dateForThirdPartyComponents | string;
    destination: string;
    hdCount: number;
    payWeight: number;
    status: boolean;
    lotId?: number;
}

export type IHandleOutstandingEstimatedHeads = (
    event: ChangeEvent<HTMLInputElement>,
    feedChargeIndex: number,
    estimatedHeadIndex: number,
) => void;

export enum ConfirmationModalOptions {
    Yes = 'Yes',
    No = 'No',
    NoneSelected = 'None Selected',
}

export enum MiscChargesOptions {
    Submit = 'Submit',
    Cancel = 'Cancel',
    NoneSelected = 'None Selected',
}

export interface ILotInformation {
    lotId: number;
    hdCount: number;
    lotPenId: string | number | null;
    estimatedHeadCount?: string | number;
    penId: string | number;
    createdDateTime: string;
}

export interface IEstimatedHeads {
    lotId: string | number;
    lotPenId: string | number | null;
    estimatedHeadCount?: string | number;
    hdCount?: string | number;
    penId: string | number;
    createdDateTime: string;
}

export interface IOutstandingEstimatedHeads {
    lotId: string | number;
    hdCount: string | number;
    penId: string | number;
    createdDateTime: string;
}

export interface IOutstandingEstimatedHeadsError {
    lotId: boolean;
    hdCount: boolean;
}

interface IAssignOutstandingFeedChargesPen {
    penId: number;
    name: string;
}

interface IEstimatedHeadTracker {
    estimatedHeads: IEstimatedHeads[];
}

export interface IAssignOutstandingFeedCharges {
    penRationHistoryId?: number;
    createdDatetime: string;
    pen: IAssignOutstandingFeedChargesPen;
    penId: number;
    rationId: number;
    rationName: string;
    deliveredAmount: number;
    estimatedHdCount: number;
    remainingEstimatedHdCount: number;
    estimatedHeadTracker: IEstimatedHeadTracker;
    outstandingEstimatedHeads: IOutstandingEstimatedHeads[];
    outstandingEstimatedHeadsError: IOutstandingEstimatedHeadsError[];
    lotInformation: ILotInformation[];
    feedingNumber: number;
    feedCharges?: IAssignOutstandingFeedChargesFeedCharge[]; // remove optional when CA-12412 is being cleaned up
}

interface IAssignOutstandingFeedChargesFeedCharge {
    date: string;
    deliveredAmount: number;
}

export interface IPens {
    name: string;
    penId: number;
}

export interface ILots {
    lotId?: number;
    name: string;
    id: number;
    state: string;
}

export interface IFeedChargesFilter {
    date: string;
    penId: number;
}

export interface IAssignOutstandingFeedChargesLotInformationRow {
    feedChargeIndex: number;
    handleOutstandingEstimatedHeads: IHandleOutstandingEstimatedHeads;
    outstandingEstimatedHeadIndex: number;
    pushToOutstandingEstimatedHeads: (feedChargeIndex: number) => void;
    popFromOutstandingEstimatedHeads: (
        feedChargeIndex: number,
        outstandingEstimatedHeadsIndex: number,
    ) => void;
    handleErrorMessage: (message: string) => void;
}

export interface IToDateSummary {
    totalCost: number;
    totalPoundsFed: number;
    totalHdDays: number;
    avgDailyCost: number;
    avgDailyConsumption: number;
}

export interface IMiscChargesModal {
    show: boolean;
    onHide: () => void;
    miscChargesResponseHandler: IMiscChargesResponseHandlerFn;
    selectedMiscCharge?: IMiscCharge;
}

export interface IMiscCharge {
    chargeId?: number;
    date: string;
    lotId?: number;
    lotName: string;
    category: string;
    miscChargeType: string;
    amount: number;
    notes?: string;
    cutoffDate?: string;
    isInGeneratedPeriod?: boolean;
    baseChargeId?: number;
}

export interface INewMiscChargesError {
    [newChargeIndexAsKey: number]: {
        lotId: boolean;
        lotName: boolean;
        category: boolean;
        miscChargeType: boolean;
        amount: boolean;
    };
}

export type IMiscChargesResponseHandlerFn = (
    response: IMiscCharge[],
    filter: IMiscFilter,
) => void;

export interface IMiscFilter extends IGenericFilter {
    limit: number;
    offset: number;
    amount: number;
    miscChargeType: string;
    category: string;
    date: string;
    lotId: number;
}

export interface IAssignOutstandingFeedChargesFilters extends IGenericFilter {
    include: string;
    limit: number;
    offset: number;
    penId: number | string;
}

export type IAssignOutstandingFeedChargesResponseHandlerFn = (
    response: IAssignOutstandingFeedCharges[],
) => void;

export enum InvoiceStatus {
    Scheduled = 'Scheduled',
    Proccessing = 'Proccessing',
    Complete = 'Complete',
}
