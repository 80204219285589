// import { ManageCommodities } from '../reducers/manageCommoditiesReducer';
// import StorageService from '../../../../Services/storage/storage.service';
// import Constants from '../../../../utils/Constants';
import { AppStartListening } from '../../../../Redux/listenerMiddleware';

export const addManageCommoditiesListeners_EXAMPLE = (
    startAppListening: AppStartListening,
) => {
    //     startAppListening({
    //         actionCreator: ManageCommodities.actions.toggleCommoditiesUnit,
    //         effect: async (action, listenerApi) => {
    //             const unit =
    //                 listenerApi.getState().manageCommodities.commoditiesUnit;
    //             new StorageService().setValue(
    //                 'commoditiesUnit',
    //                 unit,
    //                 Constants.Storage.LOCAL,
    //             );
    //         },
    //     });
};
